const colors = {
  // old mazda site:
  directAzure: "#61DFFF",
  directBlack: "#231F20",
  directDisable: "#F9F9F9",
  directVeryLightGray: "#F2F2F2",
  directGray: "#6F6F6F",
  directLightRed: "#C42F2F",
  directYellow: "#FFC320",
  chineseBlack: "#161616",
  charlestonGrey: "#2b2b2b",
  arsenic: "#414141",
  philippineGray: "#808080",
  cssYellow: "yellow",
  cyan: "#00BEBE",
  creme: "#F2F3ED",
  niogreen: "#007171",
  niolightgrey: "#CAD6D8",
  niodarkblue: "#00263C",
  nioverylightgrey: "#F7F8FA",
  niolightred: "#CB0000",
  nioSecondaryText: "#696D7F",
  cyanAccessible: "#007171",
  //
  darkBlack: "#000000",
  white: "#fff",
  transparent: "rgba(247,243,235,0)",
  // mazda:
  headerColor: "#020202",

  black: "#101010",
  darkGrey: "#999999",
  mazdaRed: "#910A2D",
  borderGrey: "#D5D5D5",
  lightGrey: "#F5F5F5",
  gainsboro: "#dadada",
  onyx: "#566066",
  brightGray: "#eeeeee",
  saphire: "#00263C",
  disable: "#616161",
  steel: "#3A3D46",
  platinum: "#E5E5E5",
  // from Lehamim:
  grey: "#f7f7f7",
  cherryRed: "#B11116",
  red: "#d70202",
  backgroundError: "#EDDBDB",
  coffeeBrown: "#45251C",
  paprika: "#A54C4C",
  wheat: "#DDD6C5",
  flour: "#F7F3EB",
  sand: "#BEB6A6",
  yellow: "#ffbc0d",
  darkYellow: "#ffb900",
  blackOlive: "#281813",
  bazil: "#61A864",
  lightBackground: "#FEFEFD",
  darkGreen: "#264f36",
  whiteGlassFill: "rgba(254, 254, 253, 0.8)",
  flourGlassFill: "rgba(247, 243, 235, 0.8)",
  memberRedGold:
    "linear-gradient(90deg, #FFDDD2 0%, #FFF3EF 16.15%, #FFDDD2 48.96%, #FFF3EF 77.08%, #FFDDD2 100%)",
  memberRedGoldSolid: "#FFDDD2",
  marbleGrey: "#857878",
  wheatOpacity: "rgba(221, 214, 197, 0.8)",
  flourOpacity: "rgba(247, 243, 235, 0.8)",

  //custom
  notExistentCream: "#dadada",
  notExistentGray: "#B5B5B5",
  notExistentDarkGray: "#777777",
  notExistentSaphire: "#B4B9BD",
  nonExistentLightGray: "#d0d3da",
  nonExistentBlue: "#0089CF",
  nonExistentLightBlue: "#e4eef8",
}

export default colors
