import { theme } from "@/lib/theme/theme"
import { Box, Theme, useMediaQuery } from "@mui/material"
import type { FC } from "react"

export const useIsDesktop = (): boolean => useMediaQuery<Theme>(theme.breakpoints.up("md"))

// Define the props for the component
interface MediaQueryComponentProps {
  aboveTheFold?: boolean
  children: React.ReactNode
  centerHorizontally?: boolean
}

export const Mobile: FC<MediaQueryComponentProps> = ({ children, centerHorizontally }) => (
  <Box
    sx={{
      display: { xs: "flex", md: "none" },
      justifyContent: centerHorizontally ? "center" : "unset",
    }}
  >
    {children}
  </Box>
)
export const Desktop: FC<MediaQueryComponentProps> = ({ children, centerHorizontally }) => (
  <Box
    sx={{
      display: { xs: "none", md: "flex" },
      justifyContent: centerHorizontally ? "center" : "unset",
    }}
  >
    {children}
  </Box>
)

export const IEMediaQuery =
  "@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none)"

export const EdgeMediaQuery = "@supports (-ms-ime-align:auto)"
