//   font-family: var(--heebo);

const GlobalStyle = `


* {
  font-family: var(--font-ploni) !important;
}

:root {
  direction: rtl;
  font-family: var(--font-ploni) !important;
}

html {
  height: 100%;
}

body {
  font-family: var(--font-ploni) !important;
  height:100%;
}
`

export default GlobalStyle
